import userImg from '../../assets/img/blog/author2.jpg';
import chart from '../../assets/img/chart.png';

const analyticsData = {
    heading: 'analytics_heading',
    subHeading: 'analytics_subheading',
    desc: 'analytics_desc',
    userName: 'user_name',
    userPosition: 'user_position',
    userPhone: 'user_phone',
    userImg,
    chart,
};

export default analyticsData;
