import { v4 as uuidv4 } from 'uuid';

const processItemData = [
    {
        id: uuidv4(),
        number: '01',
        heading: 'process_step_1_heading',
        text: 'process_step_1_text',
    },
    {
        id: uuidv4(),
        number: '02',
        heading: 'process_step_2_heading',
        text: 'process_step_2_text',
    },
    {
        id: uuidv4(),
        number: '03',
        heading: 'process_step_3_heading',
        text: 'process_step_3_text',
    },
    {
        id: uuidv4(),
        number: '04',
        heading: 'process_step_4_heading',
        text: 'process_step_4_text',
    },
];

export default processItemData;
